
import {
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
  HomeIcon

} from '@heroicons/react/outline'

type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsDatePickerModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsStatsModalOpen,
  setIsSettingsModalOpen,
}: Props) => {

  const handleHome = () => {

    window.location.href = "/";
  }

  return (
    <div className="navbar">
      <div className="navbar-content px-5 short:h-auto flex justify-between">
        <div className="flex">

          <HomeIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white" onClick={handleHome}
          />

          <InformationCircleIcon
            className="ml-3 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />

        </div>
        <div className="flex-grow flex justify-center">
          <div className={`text-center text-4xl product-sans`}>
            <div className='flex items-end'>
              <h2 className="product-sans-bold font-bold flex md:hidden">
                <span className="text-[#4285F4]">S</span>
                <span className="text-[#DB4437]">e</span>
                <span className="text-[#F4B400]">a</span>
                <span className="text-[#4285F4]">r</span>
                <span className="text-[#0F9D58]">c</span>
                <span className="text-[#DB4437]">h</span>
                <span className="text-[#4285F4]">l</span>
                <span className="text-[#0F9D58]">e</span>
              </h2>
              <span className='text-base text-black dark:text-white mb-0.5 font-bold md:hidden'>.net</span>
            </div>
          </div>
        </div>
        <div className="right-icons flex">
          <ChartBarIcon
            className="mr-3 h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsStatsModalOpen(true)}
          />
          <CogIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
