import './App.css'

import { default as GraphemeSplitter } from 'grapheme-splitter'
import { useEffect, useState } from 'react'
import Div100vh from 'react-div-100vh'
import { Link, Route, Routes } from 'react-router-dom';

import { AlertContainer } from './components/alerts/AlertContainer'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { DatePickerModal } from './components/modals/DatePickerModal'
import { InfoModal } from './components/modals/InfoModal'
import { MigrateStatsModal } from './components/modals/MigrateStatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { StatsModal } from './components/modals/StatsModal'
import { Navbar } from './components/navbar/Navbar'
import {
  DISCOURAGE_INAPP_BROWSERS,
  LONG_ALERT_TIME_MS,
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  // WELCOME_INFO_MODAL_MS,
} from './constants/settings'
import {
  CORRECT_WORD_MESSAGE,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  GAME_COPIED_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  SHARE_FAILURE_TEXT,
} from './constants/strings'
import { useAlert } from './context/AlertContext'
import { isInAppBrowser } from './lib/browser'
import {
  getStoredIsHighContrastMode,
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
} from './lib/localStorage'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  findFirstUnusedReveal,
  getIsLatestGame,
  getJustSolution,
  getLuckyOfTheDay,
  isWinningWord,
  setGameDate,
  solutionGameDate,
  unicodeLength,
} from './lib/words'
import { Input } from './components/input/Input'
import CreateYourOwn from './pages/CreateYourOwn';
import Confetti from './components/confetti/Confetti';
import { useGameContext } from './context/Custom';
import Archive from './pages/Archive';

function App() {
  const isLatestGame = getIsLatestGame()
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [shouldCheckGuess, setShouldCheckGuess] = useState(false);
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false)
  const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isVisible, setIsVisible] = useState(false);
  const [solution, setSolution] = useState(getJustSolution(false, ""));
  const [, setCreateOwn] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
        ? true
        : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [isRevealing, setIsRevealing] = useState(false)
  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage(isLatestGame)
    if (loaded?.solution !== solution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(solution)
    if (gameWasWon) {
      setIsGameWon(true)
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true)
      showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
        persist: true,
      })
    }
    return loaded.guesses
  })
  const { isGameFromLink, checkQueryParams, answer, lucky } = useGameContext();
  const [stats, setStats] = useState(() => loadStats())

  useEffect(() => {
    checkQueryParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const luckyGuess = () => {
    if (Math.random() < 0.01) {
      if (!isGameFromLink) {
        setCurrentGuess(solution.toUpperCase());
      } else {
        setCurrentGuess(answer.toUpperCase());
      }

    } else {
      if (!isGameFromLink) {
        const luckys = getLuckyOfTheDay();
        setCurrentGuess(luckys.toUpperCase());
      } else {
        setCurrentGuess(lucky.toUpperCase());
      }
    }
    setShouldCheckGuess(true);

    const today = new Date().toISOString().split('T')[0];
    localStorage.setItem('luckyGuessDate', today);
  }


  useEffect(() => {
    if (isGameFromLink) {
      setSolution(answer.toUpperCase());
      setGuesses([]);
      setIsGameWon(false);
      setIsGameLost(false);
    }
  }, [answer, isGameFromLink]);

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  // useEffect(() => {
  //   // if no game state on load,
  //   // show the user the how-to info modal
  //   if (!loadGameStateFromLocalStorage(true)) {
  //     setTimeout(() => {
  //       setIsInfoModalOpen(true)
  //     }, WELCOME_INFO_MODAL_MS)
  //   }
  // })

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 3000,
      })
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    if (!isGameFromLink) {
      saveGameStateToLocalStorage(getIsLatestGame(), { guesses, solution })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guesses])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === solution.length)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(currentGuess, guesses)
      if (firstMissingReveal) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(firstMissingReveal, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * solution.length)

    const winningWord = isWinningWord(currentGuess, isGameFromLink, answer.toUpperCase());

    if (
      unicodeLength(currentGuess) === solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])
      setCurrentGuess('')

      if (winningWord) {
        if (isLatestGame && !isGameFromLink) {
          setStats(addStatsForCompletedGame(stats, guesses.length))
        }
        if (!isGameFromLink) {
          setTimeout(() => {
            setIsStatsModalOpen(true)
          }, (solution.length + 1) * REVEAL_TIME_MS + 2000)
        }

        setTimeout(() => {
          setIsVisible(true)
          setCreateOwn(true);
        }, REVEAL_TIME_MS * solution.length + 50)
        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        if (isLatestGame && !isGameFromLink) {
          setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        }
        if (!isGameFromLink) {
          setTimeout(() => {
            setIsStatsModalOpen(true)
          }, (solution.length + 1) * REVEAL_TIME_MS + 2000)
        }
        setIsGameLost(true)
        setCreateOwn(true);
        showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
          persist: true,
          delayMs: REVEAL_TIME_MS * solution.length + 1,
        })
      }
    }
  }

  useEffect(() => {
    if (currentGuess !== '' && shouldCheckGuess) {
      onEnter();
      setShouldCheckGuess(false);  // reset after checking
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGuess, shouldCheckGuess])

  return (
    <Div100vh>
      <Navbar
        setIsInfoModalOpen={setIsInfoModalOpen}
        setIsStatsModalOpen={setIsStatsModalOpen}
        setIsDatePickerModalOpen={setIsDatePickerModalOpen}
        setIsSettingsModalOpen={setIsSettingsModalOpen}
      />
      <div className="flex-grow flex justify-center">
        <div className={`text-center text-8xl product-sans`}>
          <h2 className="product-sans-bold hidden md:flex">
            <span className="text-[#4285F4]">S</span>
            <span className="text-[#DB4437]">e</span>
            <span className="text-[#F4B400]">a</span>
            <span className="text-[#4285F4]">r</span>
            <span className="text-[#0F9D58]">c</span>
            <span className="text-[#DB4437]">h</span>
            <span className="text-[#4285F4]">l</span>
            <span className="text-[#0F9D58] relative">
              e
              {/* <span className='absolute text-base text-black dark:text-white bottom-net'>.net</span> */}
            </span>
          </h2>
        </div>
      </div>
      <Link to={"/create"}>create</Link>
      <Routes>
        <Route path="/upcoming" element={<Archive />} />
        <Route path="/create" element={<CreateYourOwn />} />
        <Route path="/" element={
          <div className="flex h-full flex-col">
            {isVisible && <Confetti />}

            {/* 
        {!isLatestGame && (
          <div className="flex items-center justify-center">
            <ClockIcon className="h-6 w-6 stroke-gray-600 dark:stroke-gray-300" />
            <p className="text-base text-gray-600 dark:text-gray-300">
              {format(gameDate, 'd MMMM yyyy', { locale: DATE_LOCALE })}
            </p>
          </div>
        )} */}

            <div className="mx-auto flex w-full flex-col px-1 pb-8 sm:px-6 md:max-w-7xl lg:px-8 short:pb-2 short:pt-2">
              <div className="my-4">
                <Input currentGuess={currentGuess} />
              </div>
              <div className="flex grow flex-col justify-start pb-2">
                <Grid
                  solution={solution}
                  guesses={guesses}
                  currentGuess={currentGuess}
                  isRevealing={isRevealing}
                  currentRowClassName={currentRowClass}
                />
              </div>
              <Keyboard
                onChar={onChar}
                onDelete={onDelete}
                onEnter={onEnter}
                solution={solution}
                guesses={guesses}
                isRevealing={isRevealing}
              />
              {((!isGameFromLink || (isGameFromLink && lucky && lucky !== "")) && guesses.length === 0) &&
                <p onClick={luckyGuess} className='text-center mt-2 text-blue-600 hover:text-blue-500 cursor-pointer'>
                  I'm feeling lucky
                </p>
              }
              {/* {isGameFromLink ? (
                <a href={window.location.origin} className='text-center mt-2 text-blue-500 dark:text-blue-400'>Return to daily game</a>
              ) : null} */}

            </div>
          </div>
        } />

      </Routes>
      <InfoModal
        isOpen={isInfoModalOpen}
        handleClose={() => setIsInfoModalOpen(false)}
      />
      <StatsModal
        isOpen={isStatsModalOpen}
        handleClose={() => setIsStatsModalOpen(false)}
        solution={solution}
        guesses={guesses}
        gameStats={stats}
        isLatestGame={isLatestGame}
        isGameLost={isGameLost}
        isGameWon={isGameWon}
        handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
        handleShareFailure={() =>
          showErrorAlert(SHARE_FAILURE_TEXT, {
            durationMs: LONG_ALERT_TIME_MS,
          })
        }
        handleMigrateStatsButton={() => {
          setIsStatsModalOpen(false)
        }}
        isHardMode={isHardMode}
        isDarkMode={isDarkMode}
        isHighContrastMode={isHighContrastMode}
        numberOfGuessesMade={guesses.length}
      />
      <DatePickerModal
        isOpen={isDatePickerModalOpen}
        initialDate={solutionGameDate}
        handleSelectDate={(d) => {
          setIsDatePickerModalOpen(false)
          setGameDate(d)
        }}
        handleClose={() => setIsDatePickerModalOpen(false)}
      />
      <MigrateStatsModal
        isOpen={isMigrateStatsModalOpen}
        handleClose={() => setIsMigrateStatsModalOpen(false)}
      />
      <SettingsModal
        isOpen={isSettingsModalOpen}
        handleClose={() => setIsSettingsModalOpen(false)}
        isHardMode={isHardMode}
        handleHardMode={handleHardMode}
        isDarkMode={isDarkMode}
        handleDarkMode={handleDarkMode}
        isHighContrastMode={isHighContrastMode}
        handleHighContrastMode={handleHighContrastMode}
      />
      <AlertContainer />
    </Div100vh>
  )
}

export default App
