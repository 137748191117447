import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { default as Filter } from 'bad-words';

const CreateYourOwn = () => {
    const { register, handleSubmit, reset, formState: { errors }, setError } = useForm({
        mode: 'onChange'
    });

    const filter = new Filter();

    const [link, setLink] = useState("");
    const [copyButtonText, setCopyButtonText] = useState("Copy Link");
    const [textAreaValue, setTextAreaValue] = useState("");

    const onSubmit = (data: any) => {
        // Check for profanity
        if (filter.isProfane(data.prompt)) {
            setError("prompt", {
                type: "manual",
                message: "Prompt contains inappropriate language"
            });
            return;
        }

        if (filter.isProfane(data.answer)) {
            setError("answer", {
                type: "manual",
                message: "Answer contains inappropriate language"
            });
            return;
        }

        // Convert data to JSON and encode it with Base64
        const encodedData = btoa(JSON.stringify(data));
        // Create the URL with the encoded data
        const url = `${window.location.origin}/?game=${encodedData}`;
        setLink(url);
        setTextAreaValue(url); // set textarea value

        // Reset the form after submit
        reset();
    };

    const copyToClipboard = () => {
        if (link) {
            navigator.clipboard.writeText(link).then(() => {
                // Change button text
                setCopyButtonText("Copied!");

                // Revert back to the original button text after 1 second
                setTimeout(() => {
                    setCopyButtonText("Copy Link");
                }, 1000);
            }).catch(err => {
                console.error("Could not copy text: ", err);
            });
        }
    }

    const playGame = () => {
        if (link) {
            window.location.assign(link);
        }
    }

    return (
        <div className='flex flex-col items-center justify-center w-full text-black dark:!text-white'>
            <div className='mt-3'>
                <h2 className='text-center text-xl mb-2'>Create your own</h2>
                <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-72'>
                    <label htmlFor='prompt' className='text-base ml-0.5'>Prompt</label>
                    <input
                        id='prompt'
                        {...register('prompt', {
                            required: "Prompt is required",
                            maxLength: {
                                value: 30,
                                message: "Prompt can't be longer than 30 characters"
                            },
                            pattern: {
                                value: /^[A-Za-z\s]*$/,
                                message: "Prompt can only contain letters and spaces"
                            }
                        })}
                        className='border-2 bg-white dark:bg-slate-700 border-gray-300 p-2 rounded-md'
                    />
                    {errors.prompt && <p className='text-red-500 dark:text-red-400'>{errors.prompt.message}</p>}

                    <label htmlFor='answer' className='text-base ml-0.5 mt-1'>Answer</label>
                    <input
                        id='answer'
                        {...register('answer', {
                            required: "Answer is required",
                            maxLength: {
                                value: 8,
                                message: "Answer can't be longer than 8 characters"
                            },
                            pattern: {
                                value: /^[A-Za-z]*$/,
                                message: "Answer can only contain letters"
                            }
                        })}
                        className='border-2 bg-white dark:bg-slate-700 border-gray-300 p-2 rounded-md'
                    />
                    {errors.answer && <p className='text-red-500 dark:text-red-400'>{errors.answer.message}</p>}
                    <button type="submit" className='bg-green-500 text-white p-2 rounded-md mt-3'>Create</button>
                </form>
                {link && (
                    <div className='w-72 mt-3 rounded-md overflow-hidden flex flex-col items-center justify-center'>
                        <p className='mb-1'>Share this with your friends</p>
                        <textarea
                            value={textAreaValue}
                            onChange={e => setTextAreaValue(e.target.value)}
                            className='text-center h-28 text-sm word-break: break-word w-full dark:bg-slate-700 rounded-md'
                        ></textarea>
                        <div className='flex gap-1'>
                            <button onClick={copyToClipboard} className='bg-blue-500 text-white px-2 py-1 rounded-md mt-3'><span>{copyButtonText}</span></button>
                            <button onClick={playGame} className='bg-[#F4b400] text-black px-2 py-1 rounded-md mt-3'><span>Play Game</span></button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CreateYourOwn;
