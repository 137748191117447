import { Game } from "@/interfaces/game";

export const GAMES: Game[] = [
    {
        "text": "is final fantasy 16",
        "answer": "online",
        "luckyGuess": "coming"
    },
    {
        "text": "will chatGPT become",
        "answer": "illegal",
        "luckyGuess": "smarter"
    },
    {
        "text": "will australia ever be",
        "answer": "invaded",
        "luckyGuess": "flooded"
    },
    {
        "text": "is human or not",
        "answer": "safe",
        "luckyGuess": "real"
    },
    {
        "text": "will i die if i eat",
        "answer": "mold",
        "luckyGuess": "glue"
    },
    {
        "text": "is human skin",
        "answer": "leather",
        "luckyGuess": "tougher"
    },
    {
        "text": "why is mario so",
        "answer": "short",
        "luckyGuess": "quick"
    },
    {
        "text": "why is mr. beast",
        "answer": "famous",
        "luckyGuess": "giving"
    },
    {
        "text": "why are americans so",
        "answer": "tall",
        "luckyGuess": "bold"
    },
    {
        "text": "what happens if I drink too much",
        "answer": "coffee",
        "luckyGuess": "liquor"
    },
    {
        "text": "can I get addicted to",
        "answer": "vape",
        "luckyGuess": "wine"
    },
    {
        "text": "do women like",
        "answer": "beards",
        "luckyGuess": "flirts"
    },
    {
        "text": "can you get blood from a",
        "answer": "turnip",
        "luckyGuess": "potato"
    },
    {
        "text": "why can't i own a",
        "answer": "tank",
        "luckyGuess": "nuke"
    },
    {
        "text": "are muslims allowed to",
        "answer": "swear",
        "luckyGuess": "drink"
    },
    {
        "text": "i ate my own",
        "answer": "poop",
        "luckyGuess": "hair"
    },
    {
        "text": "can i use string to",
        "answer": "floss",
        "luckyGuess": "braid"
    },
    {
        "text": "i like to pretend that i'm a",
        "answer": "carrot",
        "luckyGuess": "rabbit"
    },
    {
        "text": "is zelda a",
        "answer": "dragon",
        "luckyGuess": "prince"
    },
    {
        "text": "will ai destroy",
        "answer": "humanity",
        "luckyGuess": "industry"
    },
    {
        "text": "crypto made me",
        "answer": "broke",
        "luckyGuess": "smart"
    },
    {
        "text": "can a tesla be",
        "answer": "hacked",
        "luckyGuess": "stolen"
    },
    {
        "text": "my tiktok is in",
        "answer": "spanish",
        "luckyGuess": "russian"
    },
    {
        "text": "my life is",
        "answer": "potato",
        "luckyGuess": "boring"
    },
    {
        "text": "is mark zuckerberg a",
        "answer": "cyborg",
        "luckyGuess": "lizard"
    },
    {
        "text": "can donkeys",
        "answer": "laugh",
        "luckyGuess": "speak"
    },
    {
        "text": "is giga chad",
        "answer": "alive",
        "luckyGuess": "happy"
    },
    {
        "text": "why does nintendo hate",
        "answer": "luigi",
        "luckyGuess": "wario"
    },
    {
        "text": "why don't men",
        "answer": "listen",
        "luckyGuess": "change"
    },
    {
        "text": "when i jump i",
        "answer": "pee",
        "luckyGuess": "fly"
    },
    {
        "text": "what if google was a",
        "answer": "human",
        "luckyGuess": "robot"
    },
    {
        "text": "why is the world so",
        "answer": "cruel",
        "luckyGuess": "harsh"
    },
    {
        "text": "did facebook buy",
        "answer": "tiktok",
        "luckyGuess": "oculus"
    },
    {
        "text": "oh no i dropped my",
        "answer": "prime",
        "luckyGuess": "phone"
    },
    {
        "text": "do masks cause",
        "answer": "wrinkles",
        "luckyGuess": "headache"
    },
    {
        "text": "my mother is so",
        "answer": "annoying",
        "luckyGuess": "negative"
    },
    {
        "text": "tiktok is",
        "answer": "cringe",
        "luckyGuess": "addict"
    },
    {
        "text": "did elon make twitter",
        "answer": "worse",
        "luckyGuess": "money"
    },
    {
        "text": "can humans get",
        "answer": "fleas",
        "luckyGuess": "wings"
    },
    {
        "text": "i broke my",
        "answer": "phone",
        "luckyGuess": "heart"
    },
    {
        "text": "do mushrooms make you",
        "answer": "taller",
        "luckyGuess": "sicker"
    },
    {
        "text": "is it normal to have",
        "answer": "dandruff",
        "luckyGuess": "feathers"
    },
    {
        "text": "never put a sock in a",
        "answer": "toaster",
        "luckyGuess": "blender"
    },
    {
        "text": "does will smith have an",
        "answer": "oscar",
        "luckyGuess": "child"
    },
    {
        "text": "what happens if i eat too much",
        "answer": "protein",
        "luckyGuess": "noodles"
    },
    {
        "text": "why is wordle so",
        "answer": "popular",
        "luckyGuess": "complex"
    },
    {
        "text": "is bing a",
        "answer": "virus",
        "luckyGuess": "guide"
    },
    {
        "text": "are canadians",
        "answer": "american",
        "luckyGuess": "friendly"
    },
    {
        "text": "why is netflix so",
        "answer": "laggy",
        "luckyGuess": "quiet"
    },
    {
        "text": "why are the kardashians so",
        "answer": "dramatic",
        "luckyGuess": "annoying"
    },
    {
        "text": "fifa is",
        "answer": "rigged",
        "luckyGuess": "ending"
    },
    {
        "text": "my dog is so",
        "answer": "needy",
        "luckyGuess": "happy"
    },
    {
        "text": "will i die if i drink",
        "answer": "shampoo",
        "luckyGuess": "whiskey"
    },
    {
        "text": "why do cats",
        "answer": "meow",
        "luckyGuess": "purr"
    },
    {
        "text": "is solver tom",
        "answer": "human",
        "luckyGuess": "there"
    },
    {
        "text": "do fish ever",
        "answer": "drown",
        "luckyGuess": "sleep"
    },
    {
        "text": "would you please give me",
        "answer": "advice",
        "luckyGuess": "credit"
    },
    {
        "text": "fastest way to get into",
        "answer": "ketosis",
        "luckyGuess": "fitness"
    },
    {
        "text": "what to do when",
        "answer": "angry",
        "luckyGuess": "awake"
    },
    {
        "text": "how to fix a",
        "answer": "zipper",
        "luckyGuess": "button"
    },
    {
        "text": "tips for growing",
        "answer": "plants",
        "luckyGuess": "beards"
    },
    {
        "text": "how to write a",
        "answer": "resume",
        "luckyGuess": "report"
    },
    {
        "text": "effective methods for",
        "answer": "study",
        "luckyGuess": "focus"
    },
    {
        "text": "whats the science behind",
        "answer": "farming",
        "luckyGuess": "cycling"
    },
    {
        "text": "how hard is it to become a",
        "answer": "pilot",
        "luckyGuess": "actor"
    },
    {
        "text": "why are there",
        "answer": "seasons",
        "luckyGuess": "planets"
    },
    {
        "text": "what happens when you",
        "answer": "dream",
        "luckyGuess": "sleep"
    },
    {
        "text": "how to deal with",
        "answer": "stress",
        "luckyGuess": "people"
    },
    {
        "text": "the importance of having",
        "answer": "friends",
        "luckyGuess": "savings"
    },
    {
        "text": "do penguins ever have",
        "answer": "twins",
        "luckyGuess": "colds"
    },
    {
        "text": "what happens if you give a mouse a",
        "answer": "cookie",
        "luckyGuess": "cheese"
    }
]